<template>
  <div>
    <form @submit.prevent="handleSubmit" action="#">
      <div class="card">
        <div class="card-body">

          <h4 class="header-title mb-4">Car Details Fields</h4>

          <div class="row">

            <div class="col-md-12">
              <div class="form-group">
                <h5> Unique identifier field (eg. Car plate, telephone number, etc) ? </h5>
                <div class="mb-2">
                  <router-link to="/car/list" >Please note: The car identifier values should be specified in the car's detail section </router-link>
                </div>

                <b-form-checkbox
                    v-model="hasUniqueIdField"
                    value="Yes"
                    switch
                    unchecked-value="No"
                >
                  {{ hasUniqueIdField }} <span class="text-danger ml-4">Recommended value = Yes</span>
                </b-form-checkbox>
              </div>

            </div>


            <div class="col-md-6" v-if="hasUniqueIdField === 'Yes'">
              <div class="form-group">
                <label>The unique identifier field name</label>
                <input type="text" v-model="uniqueIdFieldName" class="form-control" placeholder="Enter field name here">
              </div>

            </div>


          </div>

          <!--            This is not in use any longer -->
            <h4 class="header-title mt-4">Special Fields</h4>
            <hr>
         <div class="row">
           <div class="col-md-6">
             <h6> Does excel file has a <b>total</b> field ?</h6>
             <b-form-checkbox
                 v-model="hasTotalField"
                 value="Yes"
                 switch
                 unchecked-value="No"
             >
               {{ hasTotalField }}
             </b-form-checkbox>
           </div>

           <div class="col-md-6">
             <h6> Does excel file has a <b>Date Time</b> field ?</h6>
             <b-form-checkbox
                 v-model="hasDateTimeField"
                 value="Yes"
                 switch
                 unchecked-value="No"
             >
               {{ hasDateTimeField }}
             </b-form-checkbox>
           </div>

         </div>

          <div class="row mt-3" v-if="hasTotalField === 'Yes'">
            <div class="col-md-6">
              <div class="form-group">
                <label for="car-name"> Total field name <span class="text-danger">*</span> <small class="text-bold">(separate translations with comma (,)</small></label>
                <input type="text" class="form-control"
                       placeholder="Enter total field name here"
                       required
                       id="car-name"
                       v-model="totalField"
                >
              </div>
            </div>
          </div>

          <div class="row mt-3" v-if="hasDateTimeField === 'Yes'">
            <div class="col-md-6">
              <div class="form-group">
                <label for="date-time"> Date time field name <span class="text-danger">*</span> <small class="text-bold">(separate translations with comma (,)</small></label>
                <input type="text" class="form-control"
                       placeholder="Enter date time field name here"
                       required
                       id="date-time"
                       v-model="dateTimeField"
                >
              </div>
            </div>
          </div>

        </div>
      </div>

      <div class="card">

        <div class="card-body">

          <div class="row">
            <div class="col-md-6">
              <h2 class="header-title mb-4">
                Configure Calculated Fields
              </h2>
            </div>
            <div class="col-md-6 text-right">
              <!--            <button class="btn btn-outline-dark" @click="addCalculatedField"><i class="fa fa-plus"></i> Add Field</button>-->
            </div>
          </div>


          <div class="row">
            <div class="col-md-6" :class="{'mt-5' : index !== 0 && index !== 1 }" v-for="(field, index) in calculatedFields">
              <div class="form-group">
                <label for="calculated">Enter Excel Field <small class="text-bold">(separate translations with comma (,)</small> <span class="text-danger">*</span></label>
                <div class="input-group input-group-merge">
                  <input type="text" class="form-control" id="calculated"
                         placeholder="Enter label as it will appear on mobile app"
                         required
                         v-model="field.fieldName"
                  >
                  <div class="input-group-append">
                    <div class="input-group-text bg-danger" @click="removeCalculatedField(index)" style="cursor: pointer">
                      <span class="fa fa-trash text-white"></span>
                    </div>
                  </div>
                </div>

                <b-form-group>
                  <div class="row mt-2">
                    <div class="col-md-6">
                      <b-form-radio
                          v-model="field.fieldType" :name="'calc-type-' + index"
                          value="income">Verkoop
                      </b-form-radio>
                    </div>
                    <div class="col-md-6">
                      <b-form-radio
                          v-model="field.fieldType"
                          :name="'calc-type-' + index"
                          value="cost">
                        Inkoop</b-form-radio>
                    </div>

                  </div>
                </b-form-group>

<!--           Gross / Net     -->
                <b-form-group>
                  <div class="row mt-2">
                    <div class="col-md-6">
                      <b-form-radio
                          v-model="field.grossOrNet" :name="'gross-net-' + index"
                          value="gross">Gross
                      </b-form-radio>
                    </div>
                    <div class="col-md-6">
                      <b-form-radio
                          v-model="field.grossOrNet"
                          :name="'gross-net-' + index"
                          value="net">Net
                      </b-form-radio>
                    </div>

                  </div>
                </b-form-group>



                <div class="row mt-2">
                  <div class="col-md-6">
                    <b-form-checkbox
                        v-model="field.weeklyPayment"
                        switch
                    >
                      Show field in weekly payments
                    </b-form-checkbox>
                  </div>
                  <div class="col-md-6">
                    <b-form-checkbox v-model="field.fAdministration"
                                     switch>
                      Show field in F. administration
                    </b-form-checkbox>
                  </div>
                </div>

              </div>
            </div>

            <div class="col-md-12 text-right mt-4">
              <button class="btn btn-outline-dark" type="button" @click="addCalculatedField"><i class="fa fa-plus"></i> Add New Calc Field</button>
            </div>
          </div>
        </div>
      </div>

      <div class="card">

        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <h2 class="header-title mb-4">
                Configure Tax Fields
              </h2>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6" :class="{'mt-5' : index !== 0 && index !== 1 }" v-for="(field, index) in taxFields">
              <div class="form-group">
                <div class="row">
                  <div class="col-md-6">
                    <label for="calc-field">Select Reference Field <span class="text-danger">*</span></label>
                    <select name="" id="calc-field" class="form-control" v-model="field.refFieldName" required>
                      <option value=""> -- select field -- </option>
                      <option :value="f.fieldName" v-for="f in calculatedFields"> {{f.fieldName}}</option>
                    </select>
                  </div>
                  <div class="col-md-6">
                    <label for="tax-type">Select Tax Type <span class="text-danger">*</span></label>
                    <select name="taxType" id="tax-type" class="form-control" v-model="field.taxType" required>
                      <option value=""> -- select field -- </option>
                      <option value="upper"> Upper tax </option>
                      <option value="lower"> Lower tax </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="tax-formula">Tax Formula (if applicable) <span class="text-danger">*</span></label>
                <input type="text" id="tax-formula" class="form-control" v-model="field.formula" placeholder="eg.  ref / 32 * 8" >
              </div>

              <b-form-group>
                <div class="row mt-2">
                  <div class="col-md-6">
                    <b-form-radio v-model="field.refFieldType" :name="'tax-type-' + index" value="income">Verkoop</b-form-radio>
                  </div>
                  <div class="col-md-6">
                    <b-form-radio v-model="field.refFieldType"  :name="'tax-type-' + index" value="cost">Inkoop</b-form-radio>
                  </div>
                </div>
              </b-form-group>

              <div class="form-group">
                <div class="row">
                  <div class="col-md-6">
                    <b-form-checkbox v-model="field.weeklyPayment" switch>
                      Show field in weekly payments
                    </b-form-checkbox>
                  </div>
                  <div class="col-md-6">
                    <b-form-checkbox v-model="field.fAdministration" switch>
                      Show field in F. administration
                    </b-form-checkbox>
                  </div>
                </div>
              </div>

              <button type="button" class="btn btn-outline-danger" @click="removeTaxField(index)"> <i class="fa fa-trash text-danger"></i> <span class="ml-2">Delete tax field</span></button>

            </div>

            <div class="col-md-12 text-right mt-4">
              <button class="btn btn-outline-dark" type="button" @click="addTaxField"><i class="fa fa-plus"></i> Add New Tax Field</button>
            </div>

          </div>

        </div>
      </div>

      <div class="card">

        <div class="card-body">

          <div class="row">
            <div class="col-md-6">
              <h2 class="header-title mb-4">
                Configure Additional Display Fields <br><br>
                <span class="text-danger mt-2 font-15">Please don't repeat any of the fields above to avoid duplicates</span>
              </h2>

            </div>
            <!--          <div class="col-md-6 text-right">-->
            <!--            <button class="btn btn-outline-dark"><i class="fa fa-plus"></i> Add Field</button>-->
            <!--          </div>-->
          </div>


          <div class="row">
            <div class="col-md-6" v-for="(field, index) in additionalFields">
              <div class="form-group">
                <label for="additional">Enter Excel Field <span class="text-danger">*</span></label>
                <div class="input-group input-group-merge">
                  <input type="text" class="form-control" id="additional"
                         placeholder="Enter label as it will appear on mobile app"
                         required
                         v-model="field.fieldName"
                  >
                  <div class="input-group-append">
                    <div class="input-group-text bg-danger" @click="additionalFields.splice(index, 1)"  style="cursor: pointer">
                      <span class="fa fa-trash text-white"></span>
                    </div>
                  </div>
                </div>


              </div>
            </div>

            <div class="col-md-12 text-right">
              <button type="button" class="btn btn-outline-dark" @click="additionalFields.push({fieldName: ''})"><i class="fa fa-plus"></i> Add New Additional Field</button>
            </div>

          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-body text-center">
          <button type="submit" class="btn btn-dark"> Save Fields </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import appConfig from "../../../../../app.config.json";

/**
 * Starter component
 */
export default {
  name: "ConfigureCarDataFields",
  head() {
    return {
      title: `${this.title}`,
    };
  },
  data() {
    return {
      hasUniqueIdField: 'Yes',
      uniqueIdFieldName: '',
      hasTotalField: 'Yes',
      hasDateTimeField: 'No',
      totalField: '',
      dateTimeField: '',
      calculatedFields: [],
      taxFields: [],
      additionalFields: [],
    };
  },
  computed: {
  },
  watch: {
    '$store.getters.GET_INTERNAL_DATA_CONFIG_FIELDS': function(config) {
      if(!config){
        // console.log('configs are empty: ', config);
        this.hasTotalField = "Yes";
        this.hasUniqueIdField = "Yes"
        this.uniqueIdFieldName = '';
        this.totalField = ''
        this.calculatedFields= [];
        this.taxFields = [];
        this.additionalFields= [];
        return;
      }

      this.calculatedFields = JSON.parse(config['calculated_fields'])
      this.taxFields = JSON.parse(config['tax_fields'])
      this.additionalFields = JSON.parse(config['additional_fields']);
      if(config['total_field']){
        const totalFieldPayload = JSON.parse(config['total_field']);
        this.hasTotalField = totalFieldPayload['hasTotalField']
        this.totalField = totalFieldPayload['totalField']
      } else{
          this.hasTotalField = 'Yes'
          this.totalField = ''
      }

      if(config['date_time_field']){
        const totalFieldPayload = JSON.parse(config['date_time_field']);
        this.hasDateTimeField = totalFieldPayload['hasDateTimeField']
        this.dateTimeField = totalFieldPayload['dateTimeField']
      } else{
        this.hasDateTimeField = 'No'
        this.dateTimeField = ''
      }

      if(config['unique_identifier']){
        const uniqueIdentifierFieldPayload = JSON.parse(config['unique_identifier']);
        this.hasUniqueIdField = uniqueIdentifierFieldPayload['hasUniqueIdField']
        this.uniqueIdFieldName = uniqueIdentifierFieldPayload['uniqueIdFieldName']
      }else{
        this.hasUniqueIdField = 'Yes'
        this.uniqueIdFieldName = ''
      }
    },
  },
  methods: {
    removeCalculatedField(index){
      this.calculatedFields.splice(index, 1);
    },

    removeTaxField(index){
      this.taxFields.splice(index, 1);
    },

    addCalculatedField: function (event) {
      this.calculatedFields.push({
        fieldName: '',
        fieldType: 'income', // income / cost
        grossOrNet: '',
        weeklyPayment: true,
        fAdministration: true,
      });
    },

    addTaxField: function (event) {
      this.taxFields.push({
        refFieldName: '',
        refFieldType: '', // income / cost
        taxType: '',
        formula: '',
        weeklyPayment: false,
        fAdministration: true,
      });
    },

    fetchInternalDataConfigFields(){
      this.$store.dispatch('getInternalDataConfigFields','car-config')
    },

    handleSubmit(){
      const payload = {
        calculated_fields: this.calculatedFields,
        tax_fields: this.taxFields,
        additional_fields: this.additionalFields,
        total_field: {
          hasTotalField: this.hasTotalField,
          totalField: this.totalField
        },
        date_time_field: {
          hasDateTimeField: this.hasDateTimeField,
          dateTimeField: this.dateTimeField
        },
        unique_identifier: {
          hasUniqueIdField: this.hasUniqueIdField,
          uniqueIdFieldName: this.uniqueIdFieldName
        }
      };

      this.$store.dispatch('setInternalDataConfigFields', {type: 'car-config', formData: payload});
    }

  },
  page: {
    title: "RedCabs",
    meta: [{ name: "description", content: appConfig.description }],
  },
  created() {
    this.$store.dispatch('fetchProviders');
    this.fetchInternalDataConfigFields()
  }
};
</script>


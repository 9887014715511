<template>

  <form @submit.prevent="editCar">
    <div class="card">

      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <router-link :to="`/rentals/car-detail/${carId}`" class="btn dark btn-sm">
              <i class="fas fa-arrow-left mr-1"></i>Go Back
            </router-link>
          </div>

          <div class="col-md-6">
            <h3 class="">Car Details <br><br></h3>
            <div class="form-group">
              <label>Vehicle Name</label>
              <input type="text" v-model="carName" required class="form-control" placeholder="eg. Tesla">
            </div>
          </div>
        </div>
        <hr/>
        <div class="row">
          <div class="col-md-6">
            <h2 class="header-title">
              Display Fields <br><br>
            </h2>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6" v-for="(field, index) in additionalFields">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <input type="text" required v-model="field.fieldName" class="form-control"
                         placeholder="Enter Field Name">
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <input type="text" required v-model="field.fieldValue" class="form-control"
                         placeholder="Enter Field Value">
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <button class="btn btn-sm btn-outline-danger" @click="additionalFields.splice(index, 1)">Remove
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-12 text-right">
            <button type="button" class="btn btn-outline-dark" @click="additionalFields.push({fieldName: ''})"><i
                class="fa fa-plus"></i> Add New Additional Field
            </button>
          </div>

        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <h4 class="header-title">Vehicle Images</h4>
        <p class="sub-header">Upload product image</p>
        <div class="row">
          <div class="col-md-4">
            <vue-dropzone
                id="mainImage"
                ref="mainImage"
                :use-custom-slot="true"
                :options="mainImageDropzoneOptions"
                @vdropzone-max-files-exceeded="mainImageMaxFileExceeded"
            >
              <div class="dz-message needsclick">
                <i class="h1 text-muted ri-upload-cloud-2-line"></i>
                <h3>Upload main image</h3>
              </div>
            </vue-dropzone>
          </div>
          <div class="col-md-8">
            <vue-dropzone
                id="supportingImages"
                ref="supportingImage"
                :use-custom-slot="true"
                :options="supportingImageDropzoneOptions"
                @vdropzone-max-files-exceeded="supportingImageMaxFileExceeded"
            >
              <div class="dz-message needsclick">
                <i class="h1 text-muted ri-upload-cloud-2-line"></i>
                <h3>Drop supporting images here or click to upload.</h3>

              </div>
            </vue-dropzone>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-body">

        <h4 class="header-title mb-4">Car Details Fields</h4>

        <div class="row">

          <div class="col-md-12">
            <div class="form-group">
              <h5> Unique identifier field (eg. Car plate, telephone number, etc) ? </h5>
              <div class="mb-2">
                <router-link to="/car/list">Please note: The car identifier values should be specified in the car's
                  detail section
                </router-link>
              </div>

              <b-form-checkbox
                  v-model="uniqueIdFieldName.hasUniqueIdField"
                  value="Yes"
                  switch
                  unchecked-value="No"
              >
                {{ uniqueIdFieldName.hasUniqueIdField }} <span class="text-danger ml-4">Recommended value = Yes</span>
              </b-form-checkbox>
            </div>

          </div>


          <div class="col-md-6" v-if="uniqueIdFieldName.hasUniqueIdField === 'Yes'">
            <div class="form-group">
              <label>The unique identifier field name</label>
              <input type="text" v-model="uniqueIdFieldName.uniqueIdFieldName" required class="form-control"
                     placeholder="Enter field name here">
            </div>

          </div>


        </div>

        <!--            This is not in use any longer -->
        <h4 class="header-title mt-4">Special Fields</h4>
        <hr>
        <div class="row">
          <div class="col-md-6">
            <h6> Does car has a <b>total</b> field ?</h6>
            <b-form-checkbox
                v-model="totalField.hasTotalField"
                value="Yes"
                switch
                unchecked-value="No"
            >
              {{ totalField.hasTotalField }}
            </b-form-checkbox>
          </div>

          <div class="col-md-6">
            <h6> Does excel file has a <b>Date Time</b> field ?</h6>
            <b-form-checkbox
                v-model="dateTimeField.hasDateTimeField"
                value="Yes"
                switch
                unchecked-value="No"
            >
              {{ dateTimeField.hasDateTimeField }}
            </b-form-checkbox>
          </div>

        </div>

        <div class="row mt-3" v-if="totalField.hasTotalField === 'Yes'">
          <div class="col-md-6">
            <div class="form-group">
              <label for="car-name"> Total field name <span class="text-danger">*</span> <small class="text-bold">(separate
                translations with comma (,)</small></label>
              <input type="text" class="form-control"
                     placeholder="Enter total field name here"
                     required
                     id="car-name"
                     v-model="totalField.totalField"
              >
            </div>
          </div>
        </div>

        <div class="row mt-3" v-if="dateTimeField.hasDateTimeField === 'Yes'">
          <div class="col-md-6">
            <div class="form-group">
              <label for="date-time"> Date time field name <span class="text-danger">*</span> <small class="text-bold">(separate
                translations with comma (,)</small></label>
              <input type="text" class="form-control"
                     placeholder="Enter date time field name here"
                     required
                     id="date-time"
                     v-model="dateTimeField.dateTimeField"
              >
            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="card">

      <div class="card-body">

        <div class="row">
          <div class="col-md-6">
            <h2 class="header-title mb-4">
              Configure Calculated Fields
            </h2>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6" :class="{'mt-5' : index !== 0 && index !== 1 }"
               v-for="(field, index) in calculatedFields">
            <div class="form-group">
              <label for="calculated">Enter Excel Field <small class="text-bold">(separate translations with comma
                (,)</small> <span class="text-danger">*</span></label>
              <div class="input-group input-group-merge">
                <input type="text" class="form-control" id="calculated"
                       placeholder="Enter label as it will appear on mobile app"
                       required
                       v-model="field.fieldName"
                >
                <div class="input-group-append">
                  <div class="input-group-text bg-danger" @click="removeCalculatedField(index)" style="cursor: pointer">
                    <span class="fa fa-trash text-white"></span>
                  </div>
                </div>
              </div>

              <b-form-group>
                <div class="row mt-2">
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-md-7">
                        <input type="text" class="form-control mb-2" id="calculated"
                               :placeholder="`${field.fieldName}'s value`"
                               required
                               v-model="field.fieldValue"
                        >
                      </div>
                    </div>

                  </div>
                  <div class="col-md-6">
                    <b-form-radio
                        v-model="field.fieldType" :name="'calc-type-' + index"
                        value="income">Verkoop
                    </b-form-radio>
                  </div>
                  <div class="col-md-6">
                    <b-form-radio
                        v-model="field.fieldType"
                        :name="'calc-type-' + index"
                        value="cost">
                      Inkoop
                    </b-form-radio>
                  </div>

                </div>
              </b-form-group>

              <!--           Gross / Net     -->
              <b-form-group>
                <div class="row mt-2">
                  <div class="col-md-6">
                    <b-form-radio
                        v-model="field.grossOrNet" :name="'gross-net-' + index"
                        value="gross">Gross
                    </b-form-radio>
                  </div>
                  <div class="col-md-6">
                    <b-form-radio
                        v-model="field.grossOrNet"
                        :name="'gross-net-' + index"
                        value="net">Net
                    </b-form-radio>
                  </div>

                </div>
              </b-form-group>


              <div class="row mt-2">
                <div class="col-md-6">
                  <b-form-checkbox
                      v-model="field.weeklyPayment"
                      switch
                  >
                    Show field in weekly payments
                  </b-form-checkbox>
                </div>
                <div class="col-md-6">
                  <b-form-checkbox v-model="field.fAdministration"
                                   switch>
                    Show field in F. administration
                  </b-form-checkbox>
                </div>
              </div>

            </div>
          </div>

          <div class="col-md-12 text-right mt-4">
            <button class="btn btn-outline-dark" type="button" @click="addCalculatedField"><i class="fa fa-plus"></i>
              Add New Calc Field
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="card">

      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            <h2 class="header-title mb-4">
              Configure Tax Fields
            </h2>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6" :class="{'mt-5' : index !== 0 && index !== 1 }" v-for="(field, index) in taxFields">
            <div class="form-group">
              <div class="row">
                <div class="col-md-6">
                  <label for="calc-field">Select Reference Field <span class="text-danger">*</span></label>
                  <select name="" id="calc-field" class="form-control" v-model="field.refFieldName" required>
                    <option value=""> -- select field --</option>
                    <option :value="f.fieldName" v-for="f in calculatedFields"> {{ f.fieldName }}</option>
                  </select>
                </div>
                <div class="col-md-6">
                  <label for="tax-type">Select Tax Type <span class="text-danger">*</span></label>
                  <select name="taxType" id="tax-type" class="form-control" v-model="field.taxType" required>
                    <option value=""> -- select field --</option>
                    <option value="upper"> Upper tax</option>
                    <option value="lower"> Lower tax</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="tax-formula">Tax Formula (if applicable) <span class="text-danger">*</span></label>
              <input type="text" id="tax-formula" class="form-control" v-model="field.formula"
                     placeholder="eg.  ref / 32 * 8">
            </div>

            <b-form-group>
              <div class="row mt-2">
                <div class="col-md-6">
                  <b-form-radio v-model="field.refFieldType" :name="'tax-type-' + index" value="income">Verkoop
                  </b-form-radio>
                </div>
                <div class="col-md-6">
                  <b-form-radio v-model="field.refFieldType" :name="'tax-type-' + index" value="cost">Inkoop
                  </b-form-radio>
                </div>
              </div>
            </b-form-group>

            <div class="form-group">
              <div class="row">
                <div class="col-md-6">
                  <b-form-checkbox v-model="field.weeklyPayment" switch>
                    Show field in weekly payments
                  </b-form-checkbox>
                </div>
                <div class="col-md-6">
                  <b-form-checkbox v-model="field.fAdministration" switch>
                    Show field in F. administration
                  </b-form-checkbox>
                </div>
              </div>
            </div>

            <button type="button" class="btn btn-outline-danger" @click="removeTaxField(index)"><i
                class="fa fa-trash text-danger"></i> <span class="ml-2">Delete tax field</span></button>

          </div>

          <div class="col-md-12 text-right mt-4">
            <button class="btn btn-outline-dark" type="button" @click="addTaxField"><i class="fa fa-plus"></i> Add New
              Tax Field
            </button>
          </div>

        </div>

      </div>
    </div>

    <div class="card">
      <div class="card-body text-center">
        <button type="submit" class="btn btn-dark"> Save Fields</button>
      </div>
    </div>

  </form>

</template>

<script>
import {FormWizard, TabContent} from "vue-form-wizard";
import vue2Dropzone from "vue2-dropzone";
import "vue-form-wizard/dist/vue-form-wizard.min.css";

import Layout from "../../../layouts/main";
import PageHeader from "@/components/Page-header";
import {required} from "vuelidate/lib/validators";
import {rentalsService} from "@/apis/rentals.service";

/**
 * Product-create component
 */
export default {
  name: "EditCar",
  validations: {
    carName: {
      required
    },

  },
  components: {
    Layout,
    PageHeader,
    FormWizard,
    TabContent,
    vueDropzone: vue2Dropzone,
  },
  computed: {
  },
  data() {
    return {
      mainImage: [],
      supportingFiles: [],
      stateOfCarImages: [],
      submitted: false,
      car: null,
      carName: '',

      mainImageDropzoneOptions: {
        url: '/',
        addRemoveLinks: true,
        maxFilesize: 0.5,
        acceptedFiles: ".jpeg,.jpg,.png,.gif",
        autoProcessQueue: false,
        maxFiles: 1,
      },

      supportingImageDropzoneOptions: {
        url: '/',
        maxFilesize: 0.5,
        acceptedFiles: ".jpeg,.jpg,.png,.gif",
        addRemoveLinks: true,
        autoProcessQueue: false,
        maxFiles: 3,
      },

      dropzoneOptionsStateOfCar: {
        url: '#',
        maxFilesize: 0.5,
        acceptedFiles: ".jpeg,.jpg,.png,.gif",
        addRemoveLinks: true,
        autoProcessQueue: false,
        maxFiles: 20,
      },
      uniqueIdFieldName: {
        hasUniqueIdField: 'Yes',
        uniqueIdFieldName: ''
      },
      totalField: {
        hasTotalField: 'No',
        totalField: ''
      },
      dateTimeField: {
        hasDateTimeField: 'No',
        dateTimeField: ''
      },
      calculatedFields: [],
      taxFields: [],
      additionalFields: [],
      car_config: null,
      carId: this.$route.params.id
    };
  },
  methods: {
    fetchCarConfigurations() {
      this.$store.dispatch('showLoader');
      rentalsService.getCarConfiguration(this.carId).then(response => {
        this.$store.dispatch('hideLoader');
        const {status, extra} = response;
        if (!status) {
          this.$store.dispatch('error', {message: response.message});
          return;
        }
        this.car_config = extra;
        this.car_config = extra;
        if (!this.car_config) {
          this.calculatedFields = [];
          this.taxFields = [];
          this.additionalFields = [];
          return;
        }

        this.calculatedFields = JSON.parse(this.car_config.calculated_fields)
        this.taxFields = JSON.parse(this.car_config['tax_fields'])
        this.additionalFields = JSON.parse(this.car_config['additional_fields']);
        if (this.car_config['total_field']) {
          this.totalField = JSON.parse(this.car_config['total_field']);
        }

        if (this.car_config['date_time_field']) {
          this.dateTimeField = JSON.parse(this.car_config['date_time_field']);
        }

        if (this.car_config['unique_identifier']) {
          this.uniqueIdFieldName = JSON.parse(this.car_config['unique_identifier']);
        }

      })
    },

    removeCalculatedField(index) {
      this.calculatedFields.splice(index, 1);
    },

    removeTaxField(index) {
      this.taxFields.splice(index, 1);
    },

    addCalculatedField: function (event) {
      this.calculatedFields.push({
        fieldName: '',
        fieldType: 'income', // income / cost
        grossOrNet: '',
        weeklyPayment: true,
        fAdministration: true,
      });
    },

    addTaxField: function (event) {
      this.taxFields.push({
        refFieldName: '',
        refFieldType: '', // income / cost
        taxType: '',
        formula: '',
        weeklyPayment: false,
        fAdministration: true,
      });
    },
    getCarDetails(carId) {
      this.$store.dispatch('showLoader')
      rentalsService.fetchCarDetail(carId).then((response) => {
        this.$store.dispatch('hideLoader')
        if (!response.status) {
          this.$store.dispatch('error', {message: response.message, showSwal: true})
          return;
        }
        
        this.car = response.extra
        this.carName = this.car.name

        let file = {size: this.car.mainImage.size, name: this.car.mainImage.name, type: this.car.mainImage.type};
        let url = this.car.mainImage.url;
        this.$refs.mainImage.manuallyAddFile(file, url);

        if (this.car.supportingImages) {
          this.car.supportingImages.forEach(e => {
            let file = {size: e.size, name: e.name, type: e.type};
            let url = e.url;
            this.$refs.supportingImage.manuallyAddFile(file, url);
          })
        }
      });
    },

    editCar() {
      const formData = new FormData();

      this.mainImage = this.$refs.mainImage.getAcceptedFiles();
      this.supportingFiles = this.$refs.supportingImage.getAcceptedFiles();

      if (this.mainImage.length === 0 && !this.car.mainImage) {
        this.$store.dispatch('error', {
          message: "Please upload the car's image",
          showSwal: true
        })
        return;
      }

      let mainImage = this.mainImage.length > 0 ? this.mainImage[0] : '';

      formData.append("main_image", mainImage);

      this.supportingFiles.forEach(el => {
        formData.append("supporting_images[]", el);
      })

      this.stateOfCarImages.forEach(el => {
        formData.append("car_state_images[]", el);
      })

      formData.append("name", this.carName);

      formData.append("calculated_fields", this.calculatedFields ? JSON.stringify(this.calculatedFields) : '');
      formData.append("additional_fields", this.additionalFields ? JSON.stringify(this.additionalFields) : '');
      formData.append("tax_fields", this.taxFields ? JSON.stringify(this.taxFields) : '');
      formData.append("date_time_field", this.dateTimeField ? JSON.stringify(this.dateTimeField) : '');
      formData.append("total_field", this.totalField ? JSON.stringify(this.totalField) : '');
      formData.append("unique_identifier", this.uniqueIdFieldName ? JSON.stringify(this.uniqueIdFieldName) : '');

      this.$store.dispatch('updateCar', {
        carId: this.car.id,
        formData
      })
    },

    mainImageMaxFileExceeded(file) {
      this.$refs.mainImage.removeFile(file);
    },

    supportingImageMaxFileExceeded(file) {
      this.$refs.supportingImage.removeFile(file);
    }
  },
  created() {
    this.getCarDetails(this.carId);
    this.fetchCarConfigurations();
  },
  middleware: "router-auth",
};
</script>

<style>
#myDropzone .dz-preview .dz-progress {
  opacity: .50;
}
</style>
import store from '../store/store'

export default [

    //////// AUTHENTICATION ROUTES /////////////////////////
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/pages/account/login'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {

                // If the user is already logged in
                if(store.getters.GET_USER_INFO){
                    next({name: 'Home'});
                }else {
                    // Continue to the login page
                    next()
                }

            },
        },
    },
    {
        path: '/forgot-password',
        name: 'Forgot-password',
        component: () => import('../views/pages/account/forgot-password'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {

                // If the user is already logged in
                if(store.getters.GET_USER_INFO){
                    next({name: 'Home'});
                }else {
                    // Continue to the login page
                    next()
                }

            },
        },
    },
    ///////////////////////// DASHBOARD ROUTES /////////////////////////////////
    {
        path: '/',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/dashboard'),
        children: [
            {
                path: '/',
                'name': 'Home',
                component: () => import('../views/pages/dashboard/analytics/index'),
            },
            {
                path: '/prospective-drivers',
                component: () => import('../views/pages/dashboard/driver-enrolment/driver-list'),
            },
            {
                path: '/prospective-driver-detail/:id',
                component: () => import('../views/pages/dashboard/driver-enrolment/driver-detail'),
            },
            {
                path: 'rentals/show-room',
                component: () => import('../views/pages/dashboard/car-rentals/show-room'),
            },
            {
                path: 'invoices/list/:id?',
                component: () => import('../views/pages/dashboard/invoices/list'),
            },
            {
                path: 'rentals/perform-arrival-control/:id',
                component: () => import('../views/pages/dashboard/car-rentals/perform-arrival-control-for-driver'),
            },
            {
                path: 'rentals/perform-departure-control/:id',
                component: () => import('../views/pages/dashboard/car-rentals/perform-departure-control-for-driver'),
            },
            {
                path: 'rentals/add-car',
                component: () => import('../views/pages/dashboard/car-rentals/add-new-car'),
            },
            {
                path: 'rentals/car-detail/:id',
                component: () => import('../views/pages/dashboard/car-rentals/car-detail'),
            },
            {
                path: 'rentals/edit-car/:id',
                component: () => import('../views/pages/dashboard/car-rentals/edit-car'),
            },
            {
                path: 'rentals/add-controls',
                component: () => import('../views/pages/dashboard/car-rentals/add-rental-controls'),
            },
            {
                path: 'rentals/view-controls',
                component: () => import('../views/pages/dashboard/car-rentals/view-rental-controls'),
            },
            {
                path: 'rentals/general-configurations',
                component: () => import('../views/pages/dashboard/car-rentals/config'),
            },
            {
                path: 'rentals/assign-car/:id',
                component: () => import('../views/pages/dashboard/car-rentals/assign-car'),
            },
            {
                path: 'rentals/scheduled-rentals/:id',
                component: () => import('../views/pages/dashboard/car-rentals/scheduled-rentals'),
            },
            {
                path: 'rentals/car-configurations',
                component: () => import('../views/pages/dashboard/car-rentals/configure-car-data-fields'),
            },
            {
                path: 'contracts/:slug',
                component: () => import('../views/pages/dashboard/contract-forms/contract-form'),
            },
            {
                path: 'f-admin/external-2021-upload',
                component: () => import('../views/pages/dashboard/f-administration/external-2021-data-upload'),
            },
            {
                path: 'f-admin/configure-provider-fields',
                component: () => import('../views/pages/dashboard/f-administration/configure-provider-data-fields'),
            },
            {
                path: 'f-admin/configure-receipt-fields',
                component: () => import('../views/pages/dashboard/f-administration/configure-receipt-fields'),
            },
            {
                path: 'f-admin/search-financial-data',
                component: () => import('../views/pages/dashboard/f-administration/search-financial-data'),
            },
            {
                path: 'providers/list',
                component: () => import('../views/pages/dashboard/providers/providers'),
            },
            {
                path: 'providers/upload-provider-data',
                component: () => import('../views/pages/dashboard/providers/upload-provider-data'),
            },
            {
                path: '/receipts/uploaded-receipts',
                component: () => import('../views/pages/dashboard/receipts/uploaded-receipts'),
            },
            {
                path: '/receipts/all-receipts',
                component: () => import('../views/pages/dashboard/receipts/all-receipts'),
            },
            {
                path: 'f-admin/configure-service-charge-fields',
                component: () => import('../views/pages/dashboard/f-administration/configure-service-charges'),
            },
            {
                path: 'f-admin/configure-rental-fields',
                component: () => import('../views/pages/dashboard/f-administration/configure-rental-cost'),
            },
            {
                path: 'f-admin/financial-data',
                component: () => import('../views/pages/dashboard/f-administration/financial-data'),
            },
            {
                path: 'f-admin/financial-sheet',
                component: () => import('../views/pages/dashboard/f-administration/financial-sheet'),
            },
            {
                path: 'f-admin/monthly-closing',
                component: () => import('../views/pages/dashboard/unit-4/monthly-closing'),
            },
            {
                path: 'f-admin/monthly-result',
                component: () => import('../views/pages/dashboard/unit-4/monthly-result'),
            },
            {
                path: 'f-admin/payout-initial-setup',
                component: () => import('../views/pages/dashboard/payouts/initial-arrears-setup'),
            },
            {
                path: 'f-admin/make-payout',
                component: () => import('../views/pages/dashboard/payouts/make-payout'),
            },
            {
                path: 'f-admin/arrears-overview',
                component: () => import('../views/pages/dashboard/payouts/arrears-overview'),
            },
            {
                path: 'f-admin/investigate-arrears',
                component: () => import('../views/pages/dashboard/payouts/investigate-arrears'),
            },
            {
                path: 'f-admin/payout-history',
                component: () => import('../views/pages/dashboard/payouts/payout-history'),
            },
            {
                path: 'f-admin/upload-ext-costs',
                component: () => import('../views/pages/dashboard/f-administration/upload-ext-cost'),
            },
            {
                path: 'f-admin/upload-int-costs',
                component: () => import('../views/pages/dashboard/f-administration/upload-int-cost'),
            },
            {
                path: 'unit-4/configs',
                component: () => import('../views/pages/dashboard/unit-4/configs'),
            },
            {
                path: 'unit-4/verkoop',
                component: () => import('../views/pages/dashboard/unit-4/verkoop'),
            },
            {
                path: 'unit-4/inkoop',
                component: () => import('../views/pages/dashboard/unit-4/inkoop'),
            },
            {
                path: 'unit-4/kasbank',
                component: () => import('../views/pages/dashboard/unit-4/kasbank'),
            },
            {
                path: 'unit-4/memoriaal',
                component: () => import('../views/pages/dashboard/unit-4/memoriaal'),
            },
            {
                path: 'support/chat',
                component: () => import('../views/pages/dashboard/support/chat'),
            },
            {
                path: 'receipt/chat',
                component: () => import('../views/pages/dashboard/receipts/chat-on-receipts.vue'),
            },
            {
                path: 'receipt/support',
                component: () => import('../views/pages/dashboard/receipts/support.vue'),
            },
            {
                path: 'service-charges/charges',
                component: () => import('../views/pages/dashboard/service-charges/charges'),
            },
            {
                path: 'support/bulk-messages',
                component: () => import('../views/pages/dashboard/support/bulk-messages'),
            },
            {
                path: 'support/bulk-messages-history',
                component: () => import('../views/pages/dashboard/support/bulk-messages-history'),
            },
            {
                path: 'admin/manage-admins',
                component: () => import('../views/pages/dashboard/administration/manage-admins'),
            },
            {
                path: 'settings/faq',
                component: () => import('../views/pages/dashboard/settings/faq'),
            },
            {
                path: 'settings/account-settings',
                component: () => import('../views/pages/dashboard/settings/account-settings'),
            },
            {
                path: 'drivers/list',
                component: () => import('../views/pages/dashboard/drivers/driver-list'),
            },
            {
                path: 'driver/:id',
                component: () => import('../views/pages/dashboard/drivers/edit-driver'),
            },
            {
                path: 'drivers/add',
                component: () => import('../views/pages/dashboard/drivers/add-new'),
            }
            ,
            {
                path: 'test/arrears',
                component: () => import('../views/pages/dashboard/test/arrears'),
            }

        ]
    },

    ///////// EXTRA ROUTES //////////////////////////
    {
        path: '/extras/maintenance',
        name: 'extras-maintenance',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/extras/maintenance')
    },
    {
        path: '/extras/coming-soon',
        name: 'extras-coming-soon',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/extras/coming-soon')
    },
    {
        path: '/error/404',
        name: 'error-404',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/error/404')
    },
    {
        path: '/error/500',
        name: 'error-500',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/error/500')
    }
]
import { administrationService } from "../../apis/administration.service";
// import {providersService} from "../../apis/providers.service";

const state = {
  administrators: [],
};

const getters = {
  GET_ADMINS(state) {
    return state.administrators;
  },
};

const mutations = {
  SET_ADMINS(state, admins) {
    return (state.administrators = admins);
  },
};

const actions = {
  addAdmin({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      dispatch("showLoader");
      administrationService.addAdmins(payload).then((data) => {
        dispatch("hideLoader");
        if (!data.status) {
          dispatch("error", { message: data.message, showSwal: true });
          reject();
          return;
        }

        resolve(data);
        dispatch("success", { message: data.message, showSwal: true });
      });
    });
  },
  editAdmin({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      dispatch("showLoader");
      administrationService.editAdmin(payload).then((data) => {
        dispatch("hideLoader");
        if (!data.status) {
          dispatch("error", { message: data.message, showSwal: true });
          reject();
          return;
        }

        resolve(data);
        dispatch("success", { message: data.message, showSwal: true });
      });
    });
  },

  getAdmins({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      dispatch("showLoader");
      administrationService.getAdmins().then((data) => {
        dispatch("hideLoader");
        if (!data.status) {
          dispatch("error", { message: data.message });
          reject();
          return;
        }
        resolve(data);
      });
    });
  },

  getSystemAdminUsers({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      dispatch("showLoader");
      administrationService.getSystemAdminUsers().then((data) => {
        dispatch("hideLoader");
        if (!data.status) {
          dispatch("error", { message: data.message });
          reject();
          return;
        }
        resolve(data);
        // commit('SET_ADMINS', data.extra);
      });
    });
  },


  deleteAdmin({ commit, dispatch }, admin_id) {
    return new Promise((resolve, reject) => {
      dispatch("showLoader");
      administrationService.deleteAdmin(admin_id).then((data) => {
        dispatch("hideLoader");
        if (!data.status) {
          dispatch("error", { message: data.message });
          reject();
          return;
        }
        resolve(data);
        dispatch("success", { message: data.message, showSwal: true });
      });
    });
  },
  resendPasswordAdmin({ commit, dispatch }, admin_id) {
    return new Promise((resolve, reject) => {
      dispatch("showLoader");
      administrationService.resendPasswordAdmin(admin_id).then((data) => {
        dispatch("hideLoader");
        if (!data.status) {
          dispatch("error", { message: data.message });
          reject();
          return;
        }
        resolve(data);
        dispatch("success", { message: data.message, showSwal: true });
      });
    });
  },

  getAdminPermissions({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      dispatch("showLoader");
      administrationService.getAdminPermissions().then((data) => {
        dispatch("hideLoader");
        if (!data.status) {
          dispatch("error", { message: data.message });
          reject();
          return;
        }
        resolve(data);
        // commit('SET_ADMINS', data.extra);
      });
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};

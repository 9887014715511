<template>
  <div>
    <div class="card">
      <div class="card-body">
        <h4 class="header-title mb-4">Search Financial Data ({{ (all_data || {}).total }} records) </h4>
        <form @submit.prevent="searchFinData">
          <div class="row mb-2" v-for="(d,i) in search_data" :key="i">
            <div class="col-md-3">
              <select class="form-control" v-model="d.column" required>
                <option value="user_id">Driver</option>
                <option value="field_type">field_type</option>
                <option value="field_name">field_name</option>
                <option value="field_income_cost_type">field_income_cost_type</option>
                <option value="field_value">field_value</option>
                <option value="gross_net">gross_net</option>
                <option value="gross_value">gross_value</option>
                <option value="net_value">net_value</option>
                <option value="src_of_field">src_of_field</option>
                <option value="use_in_weekly_payment">use_in_weekly_payment</option>
                <option value="use_in_financial_administration">use_in_financial_administration</option>
                <option value="has_tax_on_field">has_tax_on_field</option>
                <option value="tax_type_on_field">tax_type_on_field</option>
                <option value="tax_formula_on_field">tax_formula_on_field</option>
                <option value="use_tax_in_weekly_payment">use_tax_in_weekly_payment</option>
                <option value="use_tax_in_financial_administration">use_tax_in_financial_administration</option>
                <option value="tax_value_on_field">tax_value_on_field</option>
                <option value="status">status</option>
                <option value="trans_date_time">trans_date_time</option>
                <option value="created_at">created_at</option>
                <option value="updated_at">updated_at</option>
                <option value="invoice_generated_for_field_true_false">invoice_generated_for_field_true_false</option>
                <option value="invoice_generated_for_tax_true_false">invoice_generated_for_tax_true_false</option>
                <option value="invoice_paid_for_field_true_false">invoice_paid_for_field_true_false</option>
                <option value="invoice_paid_for_tax_true_false">invoice_paid_for_tax_true_false</option>
                <option value="invoice_id">invoice_id</option>
                <option value="tax_field_income_cost_type">tax_field_income_cost_type</option>
                <option value="payout_status">payout_status</option>
                <option value="sent_to_weekly_payment_at">sent_to_weekly_payment_at</option>
                <option value="provider_name">provider_name</option>
                <option value="receipt_id">receipt_id</option>
                <option value="week_start">week_start</option>

              </select>
            </div>
            <div class="col-md-2"
                 v-if="d.column === 'user_id'">
              <div class="form-group">
                <multiselect v-model="d.input" :options="drivers.map(driver => ((driver||{}).driver||{}).user_id)"
                             :custom-label="opt => drivers.find(x => x.driver.user_id == opt).name"
                             :multiple="false" placeholder="Select driver"></multiselect>

              </div>
            </div>
            <div class="col-md-3" v-if="d.column !== 'user_id'">
              <select class="form-control" v-model="d.operator" required>
                <option value="=">=</option>
                <option value="<"><</option>
                <option value=">"> ></option>
                <option value="<="> <=</option>
                <option value=">="> >=</option>
                <option value="IN"> IN</option>
                <option value="NOT IN">NOT IN</option>
                <option value="IS NULL">IS NULL</option>
                <option value="IS NOT NULL">IS NOT NULL</option>
                <option value="LIKE">contains</option>
                <option value="NOT LIKE">Not contains</option>
              </select>
            </div>
            <div class="col-md-3" v-if="d.column !== 'user_id'">
              <input type="text" class="form-control" v-model="d.input"/>
            </div>
            <div class="col-md-3">
              <button class="btn mr-2 btn-light" v-if="search_data.length > 1"  @click="removeSearch(i)"><i class="fa fa-minus"></i></button>
              <button class="btn btn-light" v-if="i === search_data.length-1" @click="addSearch"><i
                  class="fa fa-plus"></i>
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-md-10 mt-2 text-right">
              <button type="submit" class="btn btn-dark mr-2">Search</button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-2">
              <div class="col-sm-12 col-md-6">
                <b-button size="sm" class="mr-2" @click="selectAllRows">Select all ({{ selectedItems.length }})
                </b-button>
                <b-button size="sm" class="mr-2" @click="clearSelected">Clear selected</b-button>
                <b-dropdown right text="Delete" size="sm" variant="danger" class="mr-2">
                  <template #button-content>
                    Delete <i class="fa fa-chevron-down"></i>
                  </template>
                  <b-dropdown-item @click="deleteSelected"><span class="text-danger"> <i class="fa fa-trash-alt"/> Delete Selected</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="deleteAll"><span class="text-info"><i class="fa fa-trash"/> Delete All</span>
                  </b-dropdown-item>
                </b-dropdown>

                <b-button size="sm" variant="info" @click="downloadData"><i class="fa fa-download"/> Download Data
                </b-button>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title">Generated Links  <small class="text-danger">(download links are available to 10 mins for security purposes)</small> </h4>
                    <table class="table ">
                      <template v-for="(links, token) in tokens">
                        <template>
                          <tr>
                            <td>Generated {{ getDownloadDate(links['created_at'])}}</td>
                            <td class="text-success">{{ links['status'] }}</td>
                            <td><a :href="getDownloadUrl(links['token'])" @click="onDownloadLinkTapped(links['token'])">Click to download</a></td>
                          </tr>
                        </template>
                      </template>
                    </table>
                  </div>
                </div>

              </div>
              <div class="col-md-12">
                <b-table
                    responsive
                    sticky-header
                    table-class="table table-centered w-100"
                    thead-tr-class="bg-light"
                    :items="data"
                    :fields="fields"
                    ref="selectableTable"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    show-empty
                    selectable
                    @row-selected="onRowSelected"
                >
                  <template v-slot:cell(checkbox)="row">
                    <b-form-checkbox @click.stop v-model="row['rowSelected']"
                                     @change="onCheckBoxChanged(row)"></b-form-checkbox>
                  </template>
                  <template v-slot:cell(driver.name)="data">
                    <a target="_blank" v-if="data.item.driver"
                       :href="'/prospective-driver-detail/' + data.item.driver.id">{{ data.item.driver.name }}</a>
                    <div v-else>N/A</div>
                  </template>

                  <template v-slot:cell(field_value)="data">
                    {{ data.item.field_value | toCurrencyFormat }}
                  </template>

                  <template v-slot:cell(provider_name)="data">
                    {{ data.item.provider_name ? data.item.provider_name : 'N/A' }}
                  </template>

                  <template v-slot:cell(trans_date_time)="data">
                    {{ data.item.trans_date_time }}
                  </template>

                  <template v-slot:cell(action)="data">
                    <b-button-group>
                      <b-dropdown right text="Action" variant="primary">
                        <b-dropdown-item @click="showDetails(data.item)"><span class="text-primary">Details</span>
                        </b-dropdown-item>
                        <b-dropdown-divider></b-dropdown-divider>
                        <b-dropdown-item @click="editDetails(data.item)"><span class="text-success">Edit</span>
                        </b-dropdown-item>
                        <b-dropdown-divider></b-dropdown-divider>
                        <b-dropdown-item><span class="text-danger" @click="handleDelete(data.item)">Delete</span>
                        </b-dropdown-item>
                      </b-dropdown>
                    </b-button-group>
                  </template>


                </b-table>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div
                    class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <nav aria-label="Page navigation example">
                    <ul class="pagination">
                      <li class="page-item" :class="[link.active?'active':'']" :key="i"
                          v-for="(link,i) in (all_data || {}).links">
                        <a @click.prevent="goToPage(link)" style="cursor: pointer;" class="page-link"><span
                            v-html="link.label"></span></a>
                      </li>
                    </ul>
                  </nav>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
        id="modal-1"
        v-model="show_modal"
        :title="'Edit financial record'"
        header-close-variant="light"
        title-class="font-18"
        hide-footer
    >
      <form @submit.prevent="handleEdit">
        <div class="mt-0" v-if="bulkEdit"><span class="detail-label">Selected IDs ({{ selectedItems.length }}):</span>
          <span v-for="(s,i) in selectedItems" :key="i"> {{ s.id }} <span
              v-if=" i !==selectedItems.length-1">,</span></span>

        </div>
        <h6 class="mt-0" v-else>ID: {{ details.id }}</h6>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label>Field value</label>
              <input type="text" class="form-control" id="field_value" required v-model="details['field_value']"
                     />
            </div>
            <div class="form-group">
              <label>Gross value</label>
              <input type="text" class="form-control" id="gross_value" required
                     v-model="details.gross_value"/>
            </div>
            <div class="form-group">
              <label>Net value</label>
              <input type="text" class="form-control" id="net_value" required
                     v-model="details.net_value"/>
            </div>
            <div class="form-group">
              <label>Tax value</label>
              <input type="text" class="form-control" id="tax_value_on_field" required
                     v-model="details.tax_value_on_field"/>
            </div>
            <div class="form-group">
              <label>Trans Date</label>
              <input type="date" class="form-control" id="trans_date" required
                     :value="details.trans_date_time | toFormattedDate"/>
            </div>
          </div>
          <div class="col-md-12 text-center">
            <button type="submit" class="btn btn-success"><i class="fa fa-save"/> Save Trans Date</button>
          </div>

        </div>
      </form>

    </b-modal>
    <b-modal v-if="details.id"
             id="modal-1"
             size="lg"
             v-model="show_detail_modal"
             :title="'Full details of financial record'"
             header-close-variant="light"
             title-class="font-18"
             hide-footer
    >
      <form @submit.prevent="handleEdit">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <div class="mb-2"><span class="detail-label">ID :</span> {{ details.id }}</div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <div class="mb-2"><span class="detail-label">Driver : </span> <a target="_blank"
                                                                               :href="'/prospective-driver-detail/' + details.driver.id">{{
                  details.driver.name
                }}
                ({{ details.driver.driver.dossier }})</a></div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <div class="mb-2"><span class="detail-label">Field name : </span>{{ details.field_name }}</div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <div class="mb-2"><span class="detail-label">Trans date time : </span>{{ details.trans_date_time }}</div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <div class="mb-2"><span
                  class="detail-label">Field value : </span>{{ details.field_value | toCurrencyFormat }}
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <div class="mb-2"><span class="detail-label">Field type : </span>{{ details.field_type }}</div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <div class="mb-2"><span class="detail-label">Net value : </span>{{ details.net_value | toCurrencyFormat }}
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <div class="mb-2"><span class="detail-label">Gross net : </span>{{ details.gross_net }}</div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <div class="mb-2"><span
                  class="detail-label">Gross value : </span>{{ details.gross_value | toCurrencyFormat }}
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <div class="mb-2"><span
                  class="detail-label">Field income cost type : </span>{{ details.field_income_cost_type }}
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <div class="mb-2"><span class="detail-label">Has tax on field : </span>{{ details.has_tax_on_field }}
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <div class="mb-2"><span class="detail-label">Src field : </span>{{ details.src_of_field }}</div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <div class="mb-2"><span
                  class="detail-label">Provider name : </span>{{
                  details.provider_name ? details.provider_name : 'N/A'
                }}
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <div class="mb-2"><span
                  class="detail-label">Tax formula on field : </span>{{ details.tax_formula_on_field }}
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <div class="mb-2"><span class="detail-label">Tax type on field : </span>{{ details.tax_type_on_field }}
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <div class="mb-2"><span class="detail-label">Tax value on field : </span>{{ details.tax_value_on_field }}
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <div class="mb-2"><span class="detail-label">Week start : </span>{{ details.week_start }}</div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <div class="mb-2"><span
                  class="detail-label">Use in financial administration : </span>{{
                  details.use_in_financial_administration === '1' ? 'Yes' : 'No'
                }}
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <div class="mb-2"><span
                  class="detail-label">Use in weekly payment : </span>{{
                  details.use_in_weekly_payment === '1' ? 'Yes' : 'No'
                }}
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <div class="mb-2"><span
                  class="detail-label">Use tax in weekly payment : </span>{{
                  details.use_tax_in_weekly_payment === '1' ? 'Yes' : 'No'
                }}
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <div class="mb-2"><span
                  class="detail-label">Created at : </span>{{ details.created_at | toFormattedDate }}
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <div class="mb-2"><span
                  class="detail-label">Updated at : </span>{{ details.updated_at | toFormattedDate }}
              </div>
            </div>
          </div>
          <div class="col-md-12 text-center">
            <button type="button" @click="show_detail_modal=false" class="btn btn-danger">Close</button>
          </div>

        </div>
      </form>

    </b-modal>


  </div>
</template>

<script>
import appConfig from "../../../../../app.config.json";
import {financialAdministrationService} from "@/apis/financial.administration.service";
import DatePicker from "vue2-datepicker";
import {confirm} from "@/utils/functions";
import moment from "moment";
import Multiselect from "vue-multiselect";
import {driversService} from "@/apis/drivers.service";


/**
 * Starter component
 */
export default {
  name: "search-financial-data",
  head() {
    return {
      title: `${this.title}`,
    };
  },
  data() {
    return {
      search_data: [
        {
          operator: 'LIKE',
          column: 'user_id',
          input: ''
        }
      ],
      search_by_provider: 'all',
      pages1: [],
      drivers: [],
      details: {},
      created_range: [],
      tokens: [],
      updated_range: [],
      show_modal: false,
      show_detail_modal: false,
      stickyHeader: true,
      data: [],
      bulkEdit: false,
      field_value:'',
      net_value:'',
      tax_value_on_field:'',
      gross_value:'',
      pagination: {},
      selectedItems: [],
      total: 0,
      trans_date_time: '',
      service_type: 'all',
      status_type: 'all',
      totalRows: 1,
      currentPage: 1,
      perPage: 50,
      pageOptions: [10, 25, 50, 100, 200, 500, 1000],
      filter: null,
      filterOn: [],
      sortBy: "trans_date_time",
      sortDesc: true,
      page: this.$route.query.page ? this.$route.query.page : 0,
      fields: [
        'checkbox',
        {
          label: "Driver name",
          key: "driver.name",
          sortable: false,
        },
        {
          label: "Dossier",
          key: "driver.driver.dossier",
          sortable: false,
        },
        {
          label: "Field name",
          key: "field_name",
          sortable: false,
        },
        {
          label: "Field Income cost type",
          key: "field_income_cost_type",
          sortable: false,
        },
        {
          label: "Field type",
          key: "field_type",
          sortable: false,
        },
        {
          label: "Field value",
          key: "field_value",
          sortable: false,
        },
        {
          label: "Gross Net",
          key: "gross_net",
          sortable: false,
        },
        {
          label: "Gross Value",
          key: "gross_value",
          sortable: false,
        },
        {
          label: "Net Value",
          key: "net_value",
          sortable: false,
        },
        {
          label: "Source",
          key: "src_of_field",
          sortable: false,
        },
        {
          label: "Use_in_weekly_payment",
          key: "use_in_weekly_payment",
          sortable: false,
        }, {
          label: "use_in_financial_administration",
          key: "use_in_financial_administration",
          sortable: false,
        }, {
          label: "has_tax_on_field",
          key: "has_tax_on_field",
          sortable: false,
        }, {
          label: "tax_type_on_field",
          key: "tax_type_on_field",
          sortable: false,
        }, {
          label: "tax_formula_on_field",
          key: "tax_formula_on_field",
          sortable: false,
        }, {
          label: "use_tax_in_weekly_payment",
          key: "use_tax_in_weekly_payment",
          sortable: false,
        }, {
          label: "use_tax_in_financial_administration",
          key: "use_tax_in_financial_administration",
          sortable: false,
        }, {
          label: "tax_value_on_field",
          key: "tax_value_on_field",
          sortable: false,
        }, {
          label: "sent_to_weekly_payment_at",
          key: "sent_to_weekly_payment_at",
          sortable: false,
        },
        {
          label: "status",
          key: "status",
          sortable: false,
        }, {
          label: "tax_field_income_cost_type",
          key: "tax_field_income_cost_type",
          sortable: false,
        },
        {
          label: "Week start date",
          key: "week_start",
          sortable: false,
        },
        {
          label: "Src",
          key: "src_of_field",
          sortable: false,
        }, {
          label: "Provider name",
          key: "provider_name",
          sortable: false,
        },
        {
          label: "Trans date",
          key: "trans_date_time",
          sortable: false,
        },
        "action",
      ],
      all_data: {}
    };
  },
  components: {
    DatePicker,
    Multiselect
  },
  computed: {
    providers() {
      return this.$store.getters.GET_PROVIDERS;
    },
  },

  methods: {
    getDrivers(){
      this.$store.dispatch('showLoader')
      driversService.getDrivers(1000, '').then(data => {
        this.$store.dispatch('hideLoader')
        if (!data.status) {
          this.$store.dispatch('error', {message: data.message});
          this.drivers = [];
          return;
        }
        this.drivers =data.extra;
      })
    },
    addSearch() {
      this.search_data.push({
        operator: 'LIKE',
        column: '',
        input: ''
      })
    },
    getDownloadUrl(token){
      return process.env.VUE_APP_API_BASE_URL + '/download-financial-data/' + token
    },

    onDownloadLinkTapped(token){
      setTimeout(() => {
        this.getDownloadUrl(token)
      }, 2000)
    },

    getFinancialDownloadTokens(){
      this.$store.dispatch('showLoader')
      financialAdministrationService.getFinancialDataDownload().then((response) => {
        this.$store.dispatch('hideLoader')
        if (!response.status) {
          this.$store.dispatch('error', {message: response.message, showSwal: true});
          this.data = [];
          return;
        }
        this.tokens = response.extra;
      });
    },
    getDownloadDate(createdAt){
      return  moment(createdAt).calendar();
    },
    removeSearch(i) {
      this.search_data.splice(i, 1)
    },
    goToPage(link) {
      if (link.url) {
        this.$store.dispatch('showLoader')
        financialAdministrationService.searchFinancialDataByUrl(link.url, {search: this.search_data}).then((response) => {
          this.$store.dispatch('hideLoader')
          if (!response.status) {
            this.$store.dispatch('error', {message: response.message, showSwal: true});
            this.data = [];
            return;
          }
          this.data = response.extra.data;
          this.all_data = response.extra;
        });
      }
    },
    handleDelete(details) {
      confirm('Are you sure you want to delete this financial data. Action cannot be reverted once performed.', () => {
        const payload = {
          financial_ids: [details.id],
        }
        this.deleteFxn(payload);
      })

    },
    downloadData() {
      this.$store.dispatch('showLoader')

      financialAdministrationService.downloadFinancialData({search: this.search_data}).then((response) => {
        this.$store.dispatch('hideLoader')
        if (!response.status) {
          this.$store.dispatch('error', {message: response.message, showSwal: true})
          return;
        }
        this.$store.dispatch('success', {message: response.message, showSwal: true})
      });
    },
    deleteSelected() {
      if (this.selectedItems.length < 1) {
        alert('select items first');
        return;
      }

      confirm('Are you sure you want to delete selected financial data(s). Action cannot be reverted once performed.', () => {
        const payload = {
          financial_ids: this.selectedItems.map((e) => e.id),
        }
        this.deleteFxn(payload);
      })
    },

    deleteAll() {
      confirm('Are you sure you want to delete these records? Action cannot be reverted once performed.', () => {
        this.$store.dispatch('showLoader')
        financialAdministrationService.deleteQueriedFinancialRecords({search: this.search_data}).then((response) => {
          this.$store.dispatch('hideLoader')
          if (!response.status) {
            this.$store.dispatch('error', {message: response.message, showSwal: true})
            return;
          }
          this.$store.dispatch('success', {message: response.message, showSwal: true})
          // this.searchFinData()
        })
      })

    },

    deleteFxn(payload) {
      this.$store.dispatch('showLoader')
      financialAdministrationService.deleteFinancialData(payload).then((response) => {
        this.$store.dispatch('hideLoader')
        if (!response.status) {
          this.$store.dispatch('error', {message: response.message, showSwal: true})
          return;
        }
        this.$store.dispatch('success', {message:response.message, showSwal: true})
        this.searchFinData()

      });

    },


    onRowSelected(selectedRows) {
      this.selectedItems = selectedRows;
    },

    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },

    onCheckBoxChanged(row) {
      const index = row['index']
      if (this.$refs.selectableTable.isRowSelected(index)) {
        this.$refs.selectableTable.unselectRow(index)
      } else {
        this.$refs.selectableTable.selectRow(index)
      }
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    showDetails(detail) {
      this.details = detail;
      this.show_detail_modal = true;
    },
    editDetails(detail) {
      this.details = detail;
      this.bulkEdit = false;
      this.show_modal = true;
    },

    handleEdit() {
      const trans_date_time = document.getElementById('trans_date').value;
      if (!trans_date_time) {
        return;
      }
      const payload = {
        financial_ids: '',
        trans_date_time,
        field_value: this.details.field_value,
        net_value: this.details.net_value,
        tax_value_on_field: this.details.tax_value_on_field,
        gross_value: this.details.gross_value,

      }

      confirm(`Are you sure you want to update ${this.bulkEdit ? 'selected financial data(s)' : 'this financial data'} . Action cannot be reverted once performed.`, () => {

        if (this.bulkEdit) {
          payload.financial_ids = this.selectedItems.map((e) => e.id);
        } else {
          payload.financial_ids = [this.details.id]
        }

        this.editFxn(payload);
      })


    },

    editFxn(payload) {
      this.$store.dispatch('showLoader')
      financialAdministrationService.updateFinancialData(payload).then((response) => {
        this.$store.dispatch('hideLoader')
        if (!response.status) {
          this.$store.dispatch('error', {message: response.message, showSwal: true})
          return;
        }
        this.$store.dispatch('success', {message: response.message, showSwal: true})
        this.searchFinData();

      }).finally(() => {
        this.show_modal = false;
      })
    },


    searchFinData() {
      this.$store.dispatch('showLoader')
      financialAdministrationService.searchFinancialData({search: this.search_data}).then((response) => {
        this.$store.dispatch('hideLoader')
        if (!response.status) {
          this.$store.dispatch('error', {message: response.message, showSwal: true});
          this.data = [];
          return;
        }
        this.data = response.extra.data;
        this.all_data = response.extra;

      }).finally(() => {
      })
    },

  },
  page: {
    title: "RedCabs",
    meta: [{name: "description", content: appConfig.description}],
  },
  created() {
    this.getFinancialDownloadTokens();
    this.getDrivers();
  },

  mounted() {
    OneSignal.on('notificationDisplay', (event) => {
      let {data} = event;
      if (data.title === 'completed_financial_data') {
      this.getFinancialDownloadTokens();
      }
    });
  }

};
</script>

<style scoped>
.detail-label {
  font-weight: bold;
  font-size: 15px;
}

</style>


import axios from "../axios/axios-kaqadmin";
import { useBearerTokenHeaders } from "../utils/functions";

export const administrationService = {
  getAdmins,
  getSystemAdminUsers,
  addAdmins,
  getAdminPermissions,
  editAdmin,
  deleteAdmin,
  resendPasswordAdmin,
};

function addAdmins(payload) {
  return new Promise((resolve, reject) => {
    axios
      .post("/administration/add-admin-user", payload, useBearerTokenHeaders())
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => resolve({ status: false, message: err }));
  });
}
function editAdmin(payload) {
  return new Promise((resolve, reject) => {
    axios
      .put(
        "/administration/edit-admin-user/" + payload.id,
        payload,
        useBearerTokenHeaders()
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => resolve({ status: false, message: err }));
  });
}
function deleteAdmin(admin_id) {
  return new Promise((resolve, reject) => {
    axios
      .delete(
        "/administration/delete-admin-user/" + admin_id,
        useBearerTokenHeaders()
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => resolve({ status: false, message: err }));
  });
}
function resendPasswordAdmin(admin_id) {
  return new Promise((resolve) => {
    axios
      .post(
        "/administration/resend-password-admin-user/" + admin_id,
        {},
        useBearerTokenHeaders()
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => resolve({ status: false, message: err }));
  });
}

function getAdmins() {
  return new Promise((resolve, reject) => {
    axios
      .get("/administration/get-admin-users", useBearerTokenHeaders())
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => resolve({ status: false, message: err }));
  });
}
function getSystemAdminUsers() {
  return new Promise((resolve, reject) => {
    axios
      .get("/administration/get-system-users", useBearerTokenHeaders())
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => resolve({ status: false, message: err }));
  });
}


function getAdminPermissions() {
  return new Promise((resolve, reject) => {
    axios
      .get("/administration/get-admin-permissions", useBearerTokenHeaders())
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => resolve({ status: false, message: err }));
  });
}

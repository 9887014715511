<template>
  <div>
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Unit4 verkoop sheet generator form</h4>
        <div class="row">
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>Select provider</label>
                  <select class="form-control" v-model="selectedProviderId">
                    <option :value="provider['id']" v-for="provider in providers">{{provider['name']}}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Select year</label>
                  <select class="form-control" v-model="selectedYear">
                    <option :value="ys" v-for="ys in years">{{ys}}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label><span class="mr-2">Enter periods</span> <a href="#" class="mr-2 text-info" @click.prevent="addAllPeriods" >add all periods</a> <a href="#"  @click.prevent="removeAllPeriods" class="mr-2 text-danger">remove all periods</a> </label>
              <b-form-tags
                  input-id="tags-pills"
                  v-model="periods"
                  tag-variant="primary"
                  tag-pills
                  :tag-validator="periodTagValidator"
                  size="lg"
                  separator=" "
                  placeholder="Enter period and hit enter"
              ></b-form-tags>

            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <label>Select drivers</label>
              <b-card>
                <b-card-text>
                  <template  v-for="driver in drivers">
                    <a href="#" @click.prevent="removeDriver(driver['id'])">
                      <span class="badge badge-pill badge-soft-primary p-2 mr-2"> {{driver['name']}} {{driver['other_names']}} <i class="fas fa-trash-alt ml-2 mr-1"></i> </span>
                    </a>
                  </template>

                </b-card-text>
                <a href="#" class="card-link" @click.prevent="$bvModal.show('drivers')">Click to include drivers</a>
              </b-card>
              <share-driver-list @on-drivers-selected="onDriversSelected"></share-driver-list>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
                <button class="btn btn-success" @click="generateVerkoopBook"> Click to generate verkoop sheet</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template v-if="tokens">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Generated Links <small class="text-danger">(download links are available to 10 mins for security purposes)</small></h4>
          <table class="table ">
            <template v-for="(links, token) in tokens">
<!--              <tr>-->
<!--                <td>{{ getDownloadDate(links[0]['created_at'])}}</td>-->
<!--                <td>Contains {{links.length}} Folder (s)</td>-->
<!--                <td><a :href="getDownloadUrl(token)"  @click="onDownloadLinkTapped">Click to download</a></td>-->
<!--              </tr>-->
              <template v-if="links[0]['status'] === 'failed'">
                <tr>
                  <td>Generated {{ getDownloadDate(links[0]['created_at'])}}</td>
                  <td class="text-danger text-bold">{{ links[0]['status'] }}</td>
                  <td>{{ links[0]['status_description'] }}</td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td>Generated {{ getDownloadDate(links[0]['created_at'])}}</td>
                  <td class="text-success">{{ links[0]['status'] }}</td>
                  <td><a :href="getDownloadUrl(token)" @click="onDownloadLinkTapped">Click to download</a></td>
                </tr>
              </template>

            </template>
          </table>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import shareDriverList from '../drivers/share-driver-list'
import moment from 'moment';

export default {
  name: "verkoop",
  components: {
      shareDriverList
  },
  data(){
    return {
      selectedProviderId: null,
      drivers: [],
      periods: [],
      years: [],
      selectedYear: new Date().getFullYear(),
    }
  },
  computed: {
    providers(){
      return this.$store.getters.GET_PROVIDERS
    },
    tokens(){
      return   this.$store.getters.GET_UNIT4_DOWNLOAD_TOKENS
    }
  },
  methods: {
    onDriversSelected(drivers){
      //this.drivers.push(drivers)
      // console.log(this.drivers)
      drivers.forEach(d => {
        const findIndex = this.drivers.findIndex(e => e['id'] === d['id'])
        if(findIndex === -1){
          this.drivers.push(d)
        }
      })
    },
    removeDriver(driverId){
      const findIndex = this.drivers.findIndex(e => e['id'] === driverId)
      this.drivers.splice(findIndex,1)
    },
    periodTagValidator(tag) {
      // Individual tag validator function
      try {
        const num = JSON.parse(tag);
        if (isNaN(tag)){
          return false
        }else if(!Number.isInteger(num)){
          return false
        }else if(num < 1 || num > 12){
          return false
        }else {
          return true;
        }
      }catch (e){
      }

    },
    addAllPeriods(){
      this.periods = [1,2,3,4,5,6,7,8,9,10,11,12]
    },
    getDownloadUrl(token){
      return process.env.VUE_APP_API_BASE_URL + '/download-unit4-file/verkoop/' + token
    },
    getDownloadDate(createdAt){
     return  moment(createdAt).calendar();
    },
    removeAllPeriods(){
      this.periods = [];
    },
    generateVerkoopBook(){

      if(this.selectedYear === ''){
        this.$store.dispatch('error', {message: 'Please select the year', showSwal: true})
        return
      }
      if(this.periods.length < 1){
        this.$store.dispatch('error', {message: 'Please add at least one period', showSwal: true})
        return
      }
      if(this.drivers.length < 1){
        this.$store.dispatch('error', {message: 'Please select at least one driver', showSwal: true})
        return
      }
      if(this.selectedProviderId === ''){
        this.$store.dispatch('error', {message: 'Please select a provider', showSwal: true})
        return
      }

      const payload = {
          'year': this.selectedYear,
          'periods': this.periods,
          'drivers': this.drivers,
          'providerId': this.selectedProviderId
      }
      this.$store.dispatch('generateVerkoopBook', payload).then((token) => {

        this.drivers = []
        this.selectedProviderId = ''
        this.periods = []

        // const url = process.env.VUE_APP_API_BASE_URL + '/download-unit4-file/' + token;
        // window.open(url, "_blank")
      })
    },
    getUni4DownloadTokens(){
      this.$store.dispatch('getUni4DownloadTokens', 'verkoop')
    },
    onDownloadLinkTapped(){
      setTimeout(() => {
        this.getUni4DownloadTokens()
      }, 2000)
    },
    getYears() {
      const startYear = 2018;
      const currentYear = new Date().getFullYear();
      const endYear = currentYear + 7;
      this.years = Array.from({ length: endYear - startYear + 1 }, (_, i) => startYear + i);
    }
  },
  created() {
    this.$store.dispatch('setPageTitle', 'Verkoop')
    this.$store.dispatch('fetchProviders')
    this.getYears();
    this.getUni4DownloadTokens()

  },
  mounted() {
    OneSignal.on('notificationDisplay', (event) => {
      console.log("OneSignal notification displayed:", event);
      this.getUni4DownloadTokens()
    });
  }
}
</script>

<style scoped>

</style>